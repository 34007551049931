.tooltip{
    width: 100%;
  
}

.MuiTooltip-tooltip{
    background: #314459!important;
    padding: 10px!important;
    color: white!important;
    border: 1px solid rgba(255,255,255, 0.1);
    font-size: 13px!important;
    line-height: 20px!important;  
}

