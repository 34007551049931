.navbar {
  height: 100px;
  position: fixed;
  left: 0px;
  width: 100%;
  top: 0px;
  transition: 0.2s all;
  z-index: 999;
  .mobile-menu {
    display: none;
  }
  .mobile-toggle {
    display: none;
  }
  &-scrolled {
    background: $bg-color;
  }

  &-flex {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    @extend .grid;
  }

  &-connect {
    position: relative;
    &-connected{
      position: relative;
      background: #8C34E6;
      box-shadow: 0px 6px 20px rgb(71 67 255 / 50%);
      color: white;
      padding: 10px 20px;
      padding-right: 40px;
      &-toggle{
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translate(0, -50%);
       
      }
      &-text{
        font-size: 13px;
        font-weight: 600;
      }

      .navbar-disconnect{
        position: absolute;
        top: calc(100% + 5px);
        left: 0px;
        width: 100%;
        .main-button{
          width: 100%;
        }
      }
    }
  
    #disconnect {
      position: absolute;
      top: calc(100%);
      left: 0px;
      width: 100%;
      opacity: 0;
      pointer-events: none;
      transition: 0.2s all;
    }
    &:hover {
      #disconnect {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  &-logo {
    width: 160px;
  }

  &-left {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  .socials {
    margin-right: 80px;
    position: relative;

    &:after,
    &:before {
      position: absolute;
      right: -30px;
      width: 1px;
      height: 12px;
      background: #314459;
      content: "";
      top: 50%;
      transform: translate(0, -50%);
    }
    &:before {
      left: -40px;
    }
    &:after {
      right: -40px;
    }
  }

  &-navigation {
    display: flex;
    align-items: center;
    padding-right: 40px;
    margin-right: 40px;

    &-link {
      margin-left: 40px;

      &:first-child {
        margin-left: 0px;
      }

      a {
        color: $main-color;
        font-size: 14px;
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .navbar {
    &-navigation {
      &-link {
        margin-left: 25px;
      }
    }
  }
}

@media only screen and (max-width: 1150px) {
  .navbar {
    height: 60px;
    &-logo {
      width: 120px;
    }
    &-left {
      display: none;
    }
    &-navigation {
      flex-direction: column;
      margin-right: 0px;
      padding-right: 0px;
      &-link {
        margin-left: 0px;
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0px;
        }

        a {
          font-size: 22px;
        }
      }
    }
    .mobile-toggle {
      display: block;
      position: absolute;
      right: 0px;
    }
    .mobile-menu {
      display: flex;
      background: $bg-color;
      position: fixed;
      left: 0px;
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      top: 0px;
      left: 0px;
      z-index: 999;
      align-items: center;
      transform: translate(100%);
      transition: 0.3s all;
      &-show {
        transform: translate(0);
      }
      .navbar-connect {
        margin-top: 40px;
        zoom: 1.5;
      }
      .close-btn {
        zoom: 1.4;
        top: 15px;
        right: 15px;
      }
      .socials {
        margin: 0;
        padding: 0;
        position: absolute;
        bottom: 40px;
        &::after {
          display: none;
        }
        &::before {
          display: none;
        }
      }
    }
  }
}
