.footer{
    height: 92px;
    background: #171D23;
    margin-top: auto;
    z-index: 100;
    width: 100%;
    &-license{
        display: flex;
        align-items: center;
        gap: 10px;
        color: white;
        a{
            color: white;
        }
    }
    #footer-flex{
        display: flex;
        align-items: center;
        height: 100%;
            justify-content: space-between;
    }
    &-logo{
        width: 100px;
    }
    &-text{
        font-size: 14px;
        color: white;
    }
}

@media only screen and (max-width: 700px){
    .footer{
        height: auto;
       #footer-flex{
           flex-direction: column;
         
           padding-top: 50px;
           padding-bottom: 50px;
       } 
       &-text{
           margin-top: 40px;
           margin-bottom: 40px;
       }
    }
}