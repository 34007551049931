@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@400;700&display=swap');
@import "react-toastify/dist/ReactToastify.css";
.grid {
    max-width: 1300px;
    width: calc(100% - 80px);
    margin: 0 auto;
}

.smaller-grid {
    max-width: 1020px;
    margin: 0 auto;
}

@font-face {
    font-family: 'druk';
    src: url(../assets/fonts/druk.ttc);
  }

  @font-face {
    font-family: 'druk-medium';
    src: url(../assets/fonts/druk-medium.woff);
  }

 

$main-font: 'Lato',
sans-serif;
$title-font: 'druk';
$teko: 'Teko', sans-serif;

$title-font-bold: 'druk-medium';
$main-color: white;
$secondary-color: #BCCEFD;
$bg-color:#1C2227;
$link-color: #0188F9;

body {
    font-family: $main-font;
    overflow-x: hidden;
}

* {
    font-family: $main-font;
}

h2 {
    font-weight: bold;
    font-size: 80px;
    line-height: 90px;
    font-family: $title-font;
    color: $main-color;
}


.description-text {
    color: white;
    font-size: 16px;
    line-height: 22px;
    strong{
        font-weight: 600;
    }
}


.flex-container {

    &-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        h2 {
            margin-bottom: 20px;
        }
        &-text {
            @extend .description-text;
        }
        .main-button {
            width: fit-content;
        }
    }
}


.switch-wrapper {
    position: relative;
  }
  
  .switch-wrapper > div {
    position: absolute;
    width: 100%;
    min-height: 100vh;
  }



$mobile-sectiom-margin: 140px;
@media only screen and (max-width: 1000px){
    h2{
        font-size: 70px;
        line-height: 70px;
    }
} 
@media only screen and (max-width: 600px) {
   h2{
       font-size: 50px;
       line-height: 50px;
   }
   .grid{
       width: calc(100% - 30px);
   }
  }