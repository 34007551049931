.home-mods {
    @extend .smaller-grid;
    
    margin-top: 160px;

    &-list {
        display: flex;
        justify-content: space-between;
        gap: 50px;
        margin-top: 50px;
        flex-wrap: wrap;
        justify-content: center;

        .mod {
            box-shadow: -20px -20px 150px #D8E6F5;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 140px;
            padding-top: 20px;
            height: 190px;
            padding-bottom: 15px;

            &-frame {
                position: relative;
                width: 70px;
                height: 70px;

                &-shape {
                    position: absolute;

                    &-triangle {
                        left: -8px;
                        top: 50%;
                        transform: translate(0, -50%);
                    }

                    &-square {
                        right: -8px;
                        top: 50%;
                        transform: translate(0, -50%);
                    }

                    &-circle {
                        left: 50%;
                        transform: translate(-50%);
                        top: -8px;
                    }
                }
            }

            &-name {
                margin-top: auto;
                font-weight: 800;
                font-size: 26px;
                text-align: center;
                color: $main-color;
                margin-bottom: 15px;
                font-family: $title-font;
            }

            &-socials {
                display: flex;
                align-items: center;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    opacity: 0.5;

                    &:first-child {
                        margin-right: 15px;
                    }

                    img {}
                }
            }

            &-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    .home-mods {
        margin-top: $mobile-sectiom-margin;

    }
}

@media only screen and (max-width: 700px) {
    .home-mods {
        &-list {
            gap: 20px;
        }
    }
}