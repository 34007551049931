
.select-box-text {
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    color: $main-color;
}
.select-box {
    width: 300px;
    position: relative;
    z-index: 20;
    cursor: pointer;
    &-title{
        font-size: 12px;
        color: #727183;
        margin-bottom: 10px;
    }
    &-selected {
        height: 42px;
        border: 1px solid #314459;
        display: flex;
        align-items: center;
        padding-left: 16px;
        position: relative;
        padding-right: 35px;
        &-text{
            @extend .select-box-text;
        }
        &-placeholder{
            @extend .select-box-text;
            opacity: 0.7; 
        }
        &-open{
            border-bottom: 1px solid transparent;
        }
        button{
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: 15px;
        }
    }

    &-list {
        max-height: 200px;
        overflow-y: auto;
        border: 1px solid #314459;
        border-top: none;
        background: white;
        position: absolute;
        left: 0px;
        top: calc(100% - 1px);
        width: 100%;

        &-item {
          
            padding-left: 16px;
            min-height: 40px;
            padding-top: 10px;
            padding-bottom: 10px;
            display: flex;
            align-items: center;
            @extend .select-box-text;
            transition: 0.2s all;
            &:hover{
                background:rgba(140, 52, 230, 0.05) ;
            }

        }
    }

}