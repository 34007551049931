.input-container {
    width: 100%;
    position: relative;
     &-title{
        font-size: 12px;
        color: #727183;
        margin-bottom: 10px;
    }
}
.input {
    border: 1px solid black;
    color: black;
    text-indent: 20px;
    font-weight: bold;
    font-size: 16px;
    width: 100%;
    resize: none;
    height: 42px;
    transition: 0.2s all;
  
    &-dirty{
        border: 1px solid red!important;
    }
    
    &-error{
        color: white;
        font-size: 13px;
        top: calc(100% + 10px);
        width: 100%;
        text-align: left;
    }
    &::placeholder{
        color: black;
        opacity: 0.6;
    }
}
.textarea{
    @extend .input;
    padding-top: 10px;
    height: 170px;
}