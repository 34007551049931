.tx-error {
  .popup-content {
    max-width: 600px;
    padding: 40px;
    width: calc(100vw - 50px);
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
  }

  &-message {
    max-height: 300px;
    text-align: center;
    p {
      @extend .description-text;
    }
  }
  .main-button {
    margin-top: 40px;
  }
}
