
.video{
    position: relative;
  &-volume{
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 10;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0,0,0, 0.5);
      border-radius: 50%;
      opacity: 0;
      transition: 0.2s all;
  }
  &:hover{
      .video-volume{
          opacity: 1;
      }
  }
    video{
        width:100%;
        height: 100%;
        position: relative;

    }
 
}

@media only screen and (max-width: 600px){
    .video-volume{
        opacity: 1;
    }
}