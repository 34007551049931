
html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video{
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    text-size-adjust: none;
}

footer, header, nav, section, main{
    display: block;
}

html {
    background-color: #252F39;
}

body{
    line-height: 1;

}
.App{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}


ol, ul{
    list-style: none;
}

blockquote, q{
    quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after{
    content: '';
    content: none;
}

table{
    border-collapse: collapse;
    border-spacing: 0;
}

input{
    -webkit-appearance: none;
    border-radius: 0;
}

*{
    box-sizing: border-box;
    outline: none;
}

a{
    text-decoration: none;
}

button{
    cursor: pointer;
    border: none;
    background: transparent;
    padding: 0px;
}


.MuiBackdrop-root{
    backdrop-filter: blur(8px);
    background: rgba(0,0,0, 0.8)!important;

}

.MuiPaper-root{
    border-radius: 0px!important;
     box-shadow: 0px -20px 150px rgba(250, 252, 255, 0.5)!important;
    max-width: unset!important;
    margin: 0px!important;
}

.walletconnect-modal__base{
    max-width: 400px!important;
    padding:15px!important;
}

.SnackbarItem-variantInfo{
    background-color: #232C35!important;
}