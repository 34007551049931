.popup {
  .close-btn {
    position: absolute;
    right: 50px;
    top: 50px;
   
  }
  &-content {
    width: calc(100% - 30px);
    position: relative;
   
  }
}


@media only screen and (max-width: 600px) {
  .MuiDialog-container{
    .MuiPaper-root{
     justify-content: flex-start!important;
     padding-top: 20px;
    }
  }
}