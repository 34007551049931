
.merge{
   
    &-flex{
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;
    }
    &-hero{
        width: 420px;
        height: 420px;
        position: relative;
        img{
            position: absolute;
            right: 0px;
            width: 600px;
            bottom: 0px;
        }
    }
    &-description{
      
       
        margin-left: 80px;
        display: flex;
        flex-direction: column;
        padding-bottom: 30px;
        padding-top: 30px;
        max-width: 600px;
        .description-text{
            margin-bottom: 20px;
        }
        h2{
            margin-bottom: 20px;
         
        }
        .main-button{
            margin-top: auto;
            width: fit-content;
        }
    }
}


@media only screen and (max-width: 1200px) {
    .merge {
        &-hero {
            width: 380px;
            height: 380px;
            img{
                width: 560px;
            }
        }

        &-description {
            margin-left: 60px;
        }
    }
}

@media only screen and (max-width: 1000px) {
    .merge {
        &-flex {
            flex-direction: column;
            align-items: center;
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
        }

        &-description {
            order: 1;
            text-align: center;
            margin-left: 0px;
            align-items: center;
            padding: 0px;
            margin-bottom: 80px;

            .main-button {
                margin-top: 30px;
            }
        }

        &-hero {
            order: 2;
            width: 100%;
            position: relative;
            img{
                left: 30%;
                min-width: 300px;
                top: 30%;
                width: 100%;
                right: unset;
                bottom: unset;
               transform: translate(-50%, -50%);
            }
        }
    }
}