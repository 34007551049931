.home-pr{
    max-width: 912px;
    margin: 0 auto;
    margin-top: 180px;
    &-list{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 28px;
        flex-wrap: wrap;
        &-item{
            width: 160px;
            height: 160px;
            object-fit: cover;
        }
    }
    #big-video{
        background: black;
        width: 100%;
        margin-top: 40px;
        height: 560px;
        position: relative;
    }
}

@media only screen and (max-width: 700px){
    .home-pr{
        margin-top: $mobile-sectiom-margin;
        &-list{
            gap:20px;
            &-item{
                width: 140px;
                height: 140px;
            }
        }
        #big-video{
            height: 360px;
        }
    }
}
