.home-brands {
    position: relative;
    @extend .smaller-grid;
    display: flex;
    margin-top: 220px;
    flex-direction: column;
    align-items: center;

    &-list {
        position: relative;
        display: flex;
        z-index: 1;
        gap: 35px;
        justify-content: center;
        align-items: stretch;
        flex-wrap: wrap;

        &-item {
            box-shadow: 0px 20px 50px rgba(216, 230, 245, 0.8);
            background: white;
            width: 226px;
            padding: 25px;
            min-height: 345px;

            &-img {
                width: 100%;
            }

            &-text {
                margin-top: 20px;
                @extend .description-text;
                font-size: 12px;
                line-height: 14px;
            }
        }
    }

    #brands-see-more {
        margin: 0 auto;
        margin-top: 80px;
    }

    &-lines {
        position: absolute;
        left: 0px;
        top: 200px;
        width: 100%;

        z-index: 0;

        .fading-line {
            height: 500px;
        }

        #line-1 {
            left: 0px;
        }

        #line-2 {
            left: 200px;
        }

        #line-3 {
            left: 285px;
            top: -200px;
        }

        #line-4 {
            right: 285px;
            top: -200px;
        }

        #line-5 {
            right: 200px;

        }

        #line-6 {
            right: 0px;

        }

    }
}

@media only screen and (max-width: 1000px) {
    .home-brands {
        margin-top: $mobile-sectiom-margin;

        &-list {
            gap: 20px;
            flex-wrap: wrap;

        }

        #brands-see-more {
            margin-top: 60px;
        }
    }
}


@media only screen and (max-width: 600px) {
    .home-brands {

        &-list {
            gap: 20px;
            flex-wrap: wrap;
        }
        &-lines{
            display: none;
        }


        #brands-see-more {
            margin-top: 60px;
        }
    }
}