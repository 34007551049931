.fading-line {
    position: absolute;
    width: 1px;
    z-index: 0;
    height: 900px;

    figure {
        width: 100%;
        height: 100%;
        background: white;
        opacity: 0.2;
    }
}