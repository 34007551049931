.wallet-select {
  position: relative;
  background: transparent;
  .popup-content{
    max-width: 350px;
    background: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:20px;
    border-radius: 20px;
    h3{
      font-size: 40px;
      font-weight: bold;
      color: #314459;
      font-family: $title-font;
    }
  }
  
  &-list {

    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    padding-top: 20px;
    li {
      transition: 0.2s all;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 10px 20px;
    
      img {
        width: 40px;
        object-fit: contain;
      }
      p {
        padding-left: 20px;
        color: #314459;
        font-weight: 500;
        font-size: 16px;
      }
      &:hover {
        background: rgba(0, 0, 0, 0.05);
      }
    }
  }
  &-bg {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
  }
}

#wallet-select {
  .MuiPaper-root {
    background: transparent !important;
  }
}
