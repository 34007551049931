@import "./Lines/style.scss";
@import './Shapes/styles.scss';

.home-hero {
  position: relative;
  padding-top: 140px;
  padding-bottom: 80px;
  .grid {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    text-align: center;
  }
  &-top-text {
    font-family: $title-font;
    color: $main-color;
    font-weight: 500;
    font-size: 40px;
    margin-bottom: 40px;
  }
  &-title {
    font-family: $title-font;
    color: $main-color;
    font-weight: 800;
    font-size: 100px;
    line-height: 110px;
    margin-bottom: 40px;
    max-width: 900px;
  }
  &-text {
    font-family: $main-font;
    color: $main-color;
    font-size: 16px;
    line-height: 20px;
    max-width: 855px;

    p {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  &-button {
    margin-top: 40px;
    margin-bottom: 5px;
    text-transform: uppercase;
    .main-button {
      min-width: 160px;
    }
  }
  &-video {
    margin-top: 40px;
    max-width: 560px;
  }
}

@media only screen and (max-width: 1400px) {
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 1000px) {
    .home-hero{
        padding-top: 100px;
        &-top-text{
            font-size: 34px;
            margin-bottom: 20px;
        }
        &-title{
            font-size: 42px;
            line-height: 50px;

            margin-bottom: 20px;
        }
    }
}

@media only screen and (max-width: 600px) {
}
