#license {
  color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 150px;
  .license-content {
      padding-bottom: 100px;
    max-width: 900px;
    width: calc(100% - 40px);
    h1{
        font-size: 32px;
        text-align: center;
        font-weight: bold;
        margin-bottom: 50px;
    }
    h5 {
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 20px;
    }
    section {
        margin-bottom: 40px;
    }
    p {
        font-size: 16px;
        line-height: 20px;
    }
  }
}

@media only screen and (max-width: 600px) {
    #license{
        padding-top: 90px;
    }
  }
