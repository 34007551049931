.transaction-success{
    min-width: 600px;
    min-height: 300px;
    background: white;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .main-button{
        margin-top: auto;
    }
}