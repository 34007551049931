.close-btn {
  position: absolute;
  width: 40px;
  height: 40px;
  z-index: 10;
  right: 15px;
  top: 15px;
  z-index: 999;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
