@import './Hero/style.scss';
@import './Minting/style.scss';
@import './Mergin/style.scss';
@import './Team/style.scss';
@import './Roadmap/style.scss';
@import './Brands/style.scss';
@import './Founders/style.scss';
@import './Chart/style.scss';
@import './Events/style.scss';
@import './Mods/style.scss';
@import './Metaverse/style.scss';
@import './Perks/style.scss';
@import './Press/style.scss';
@import './NftNyc/style.scss';


.home {

    &-top{
        background: linear-gradient(180deg, #252F39 0%, #15181C 100%);

    }
    &-main{
        background: $bg-color;
        padding-bottom: 300px;
    }
    &-flex {
        width: 960px;
        margin: 0 auto;
    }

    &-section-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        z-index: 1;
        margin-bottom: 60px;
        text-align: center;
        h2{
            margin-bottom: 20px;
        }
        h5, a {
            max-width: 600px;
            color: $secondary-color;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
        }
    }


}



@media only screen and (max-width: 1000px){
    .home{
        overflow-x: hidden;
        &-main{
            padding-bottom: 90px;
        }

    }
}
