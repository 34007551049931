.mint {
  &-action {
    margin-top: 50px;
  }
  &-flex {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &-hero {
    width: 400px;
    height: auto;
    object-fit: contain;
  }

  &-description {
    max-width: 520px;
    margin-left: 80px;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    padding-top: 30px;
    h2 {
      margin-bottom: 20px;
    }

    .main-button {
      margin-top: auto;
      width: fit-content;
    }
  }
}

.description-text {
  a {
    color: white;
    text-decoration: underline;
  }
}

@media only screen and (max-width: 1200px) {
  .mint {
    &-hero {
      width: 380px;
      height: auto;
    }

    &-description {
      margin-left: 60px;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .mint {
    &-flex {
      flex-direction: column;
      align-items: center;
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    }

    &-description {
      order: 1;
      text-align: center;
      margin-left: 0px;
      align-items: center;
      padding: 0px;
      margin-bottom: 80px;

      .main-button {
        margin-top: 30px;
      }
    }

    &-hero {
      order: 2;
      width: 100%;
    }
  }
}
