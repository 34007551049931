.home-lines {
    height: calc(100% - 80px);
    position: absolute;
    top: 0px;
    width: 100%;
  .fading-line {
    height: 100%;
    position: absolute;
    top: 0px;
  }
  &-right {
    height: 100%;
    position: absolute;
    top: 0px;

    right: 0px;
    #line-1 {
      right: 10px;
    }
    #line-2 {
      right: 1vw;
    }
    #line-3 {
      right: 2vw;
    }
    #line-4 {
      right: 4vw;
    }
    #line-5 {
      right: 8vw;
    }
    #line-6 {
      right: 20vw;
    }
    #line-7 {
      right: 35vw;
    }
  }
  &-left {
    height: 100%;
    position: absolute;
    top: 0px;

    left: 0px;

    #line-1 {
      left: 10px;
    }
    #line-2 {
      left: 1vw;
    }
    #line-3 {
      left: 2vw;
    }
    #line-4 {
      left: 4vw;
    }
    #line-5 {
      left: 8vw;
    }
    #line-6 {
      left: 20vw;
    }
    #line-7 {
      left: 35vw;
    }
  }
}
