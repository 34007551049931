.loading-image{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $main-color;
    transition: 0.2s all;
    &-loaded{
        border: 1px solid transparent;
    }
    
    img{
        width: 100%;
        height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        object-fit: cover;
    }
    .spinner{
        position: relative;
        z-index: 10;
    }
}