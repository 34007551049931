@import './StepsButtons/style.scss';

.merge-steps {
    flex: 1;
    padding: 50px 60px 50px 60px;
    display: flex;
    flex-direction: column;

    &-step {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .base-step {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-bottom: 30px;

        &-text {
            @extend .description-text;
        }

        &-header {
            >h3 {
                font-weight: 900;
                font-size: 40px;
                line-height: 48px;
                color: $main-color;
                margin-bottom: 30px
            }

            >p {
                @extend .description-text;
            }

            margin-bottom: 30px;

            .select-box,
            .input-container {
                margin-top: 30px;
            }
        }

        &-image {
            margin-top: auto;
            height: 389px;
            .loading-image {
                width: 100%;
                    height: 100%;
               
                img {}
            }
        }

        .input {
            color: $main-color;

            &::placeholder {
                color: $main-color;
                opacity: 0.6;
            }

            border: 1px solid $main-color;
        }

    }
}