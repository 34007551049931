.chart {
    margin-top: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
    flex-direction: column;
    align-items: center;
    &-image {
        width: 100%;
        height: 100%;
        z-index: 1;
        position: relative;
        left: -6px;
    }

    &-content {
        width: 620px;
        height: 620px;
        position: relative;
    }

    &-dot {
        width: 20px;
        height: 20px;
        background: $main-color;
        border: 4px solid #FFFFFF;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        position: absolute;
        z-index: 2;
        &-1 {
            top: 25%;
            left: 10%;
        }

        &-2 {
            top: 18%;
            right: 15%;
        }

        &-3 {
            top: 81%;
            right: 20%;
        }

        &-4 {
            top: 88.5%;
            left: 34%;
        }
    }
    &-line{
        position: absolute;
        height: 140%;
        .fading-line{
            height: 100%;
            max-height: 800px;
            
        }
        &-1{
            transform: rotate(-72deg)!important;
            left: 0px;
            top: 201px;
            transform-origin: left top;
        }
        &-2{
            transform: translate(-50%) !important;
            left: 50%;
            top: -30px;
            transform-origin:top;
        }
        &-3{
            transform: rotate(36deg) !important;
            right: 65.5%;
            top: 0%;
            transform-origin:center;
        }
        &-4{
            display: none;
        }
    }

    &-percent {
        position: absolute;
        font-weight: 800;
        font-size: 55px;
        color: white;
        font-family: $title-font;
        z-index: 2;
        &-1{
            left: 13%;
            top: 28%;
        }
        &-2{
            right: 16%;
            top: 23%;
        }
        &-3{
            right: 22%;
            top: 71%;
        }
        &-4{
            left: 32%;
            top: 77%;
        }
    }

    &-text {
        font-weight: 500;
        font-size: 36px;
        line-height: 43px;
        font-family: $title-font;
        position: absolute;
        color: $main-color;
        max-width: 125px;
        
        &-1{
            left: -15%;
            top: 13%;
        }
        &-2{
            right: -8%;
            top: 5%;
        }
        &-3{
            right: -2%;
            top: 85%;
        }
        &-4{
            left: 19%;
            top: 93%;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .chart{
        &-content{
            transform: scale(0.8);
        }
    }
}
@media only screen and (max-width: 1000px) {
    .chart {
        margin-top: $mobile-sectiom-margin;

    }
}
@media only screen and (max-width: 700px) {
    .chart{
       justify-content: flex-start;
        height: 460px;
        position: relative;
        &-content{
            top: 20px;
            position: absolute;
            transform: scale(0.4);
        }
        &-text{
            font-size: 40px;
        }
    }
}