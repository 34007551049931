.mint-popup {

  .send-email{
    width: 100%;
    .main-button {
      margin-top: 30px;
    }
    .input-container{
      margin-top: 30px;
    }
    &-success{
        display: flex;
        align-items: center;
        justify-content: center;
        gap:10px;
        margin-top: 30px;
        p{
          font-size: 18px;
        }
    }
  }
  .popup-content {
    max-width: 477px;
    width: calc(100vw - 50px);
    padding: 40px 60px 60px 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
  }
  &-total{
    font-weight: 600;
    font-size: 14px;
    margin-top: 10px;
  }

  .close-btn {
  }
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .counter {
    margin-top: 30px;
  }
  &-text {
    @extend .description-text;
    text-align: left;
    color: #314459;
  }
  &-title {
    margin-bottom: 20px;
    color: #314459;
  }

  .main-button {
    width: 100%;
  }
  &-action {
    margin-top: 35px;
    width: 100%;
    &-warning{
        text-align: center;
        font-size: 14px;
        margin-top: 10px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .mint-popup {
    padding: 40px 30px 40px 30px;
    .counter {
      zoom: 1.1;
    }
  }
}
