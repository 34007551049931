.moonpay{
    .popup-content{
        display: flex;
        align-items: center;
        background: white;
        width: 420px;
        height: 620px;
        border-radius: 20px;
        .spinner{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
    iframe{
      width: 100%;
      height: 100%;
      border-radius: 20px;
     
    }
}