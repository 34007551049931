.home-events {
    @extend .smaller-grid;
    display: flex;
    flex-direction: column;
    margin-top: 180px;
    align-items: center;

    &-list {
        display: flex;
        align-items: stretch;
        gap: 50px;
        justify-content: center;
        flex-wrap: wrap;

        .event {
            box-shadow: -20px -20px 150px #D8E6F5;
            height: 375px;
            position: relative;
            display: flex;
            overflow: hidden;
            width: 287px;

            &-content {
                margin-top: auto;
                position: relative;
                color: white;
                padding: 25px;
                z-index: 2;
            }

            &-title {
                font-weight: 800;
                font-size: 26px;
                line-height: 31px;
                font-family: $title-font;
            }

            &-date {
                font-weight: bold;
                font-size: 12px;
                margin-bottom: 10px;
            }

            &-text {
                font-size: 16px;
                line-height: 22px;
            }

            &-shadow {
                box-shadow: inset 0px -50px 25px 62px rgba(119, 119, 119, 0.7);
                position: absolute;
                left: 50%;
                transform: translate(-50%);
                top: 0px;
                z-index: 1;
                width: 150%;
                height: 100%;
                pointer-events: none;
            }

            &-image {
                position: absolute;
                left: 0px;
                top: 0px;
                width: 100%;
                height: 100%;
                object-fit: cover;

            }
        }
    }

    .main-button {
        margin-top: 50px;
    }
}

@media only screen and (max-width: 1000px) {
    .home-events {
        margin-top: 100px;

    }
}

@media only screen and (max-width: 700px) {
    .home-events {
        &-list {
            gap: 20px;
        }
    }
}