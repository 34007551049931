.perks {
  max-width: 1020px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 60px;
    justify-content: center;
    li {
      width: calc(33% - 40px);
      background: #ffffff;
      padding: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      min-height: 231px;
      padding-top: 40px;
      img {
        margin-bottom: 30px;
      }
      p {
        color: #727183;
        font-family: $main-font;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .perks {
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 120px;
    ul {
      gap: 10px;
      li {
          min-height: unset;
        width: calc(50% - 10px);
        padding: 12px;
        padding-top: 20px;
        padding-bottom: 20px;
        img{
            margin-bottom: 20px;
            zoom: 0.8;
        }
        p{
            font-size: 14px;
            line-height: 18px;
        }
      }
    }
  }
}
