.home-team {
  max-width: 1012px;
  margin: 0 auto;
  margin-top: 240px;

  &-members {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    flex-wrap: wrap;
    gap: 40px 0px;
    padding-top: 100px;
    .member {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
      width: calc(100% / 5);
      min-height:350px;
      align-items: center;
      &-images {
        width: 128px;
        height: 128px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-shape {
        position: absolute;
       
        pointer-events: none;
        top: -60px;
        left: 50%;
        transform: translate(-50%);
        transition: 0.2s all;
      }
      figure{
        width: 100%;
        height:100%;
        overflow: hidden;
        border-radius: 50%;
      
      }
      &-details {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding-top: 17px;
        transition: 0.3s all;
        padding-left: 10px;
        padding-right: 10px;
        h4 {
          font-weight: 600;
          font-weight: 800;
          font-size: 26px;
          color: $main-color;
          font-family: $title-font;
        }

        h5 {
          font-size: 14px;
          line-height: 22px;
          margin-top: 5px;
          margin-bottom: 5px;
          color: $main-color;
        }
        p {
          color: $main-color;
          font-size: 12px;
          line-height: 14px;
        }
        a{
          color: $link-color;
        }
       
        &-avatar {
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: 10;
        
         transform: scale(1.1);
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .home-team {
    &-members {
      zoom: 0.9;
      max-width: 900px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .home-team {
    margin-top: 70px;
    &-members {
      zoom: 1;
      flex-wrap: wrap;
      height: auto;
      gap: 100px 20px;
      .member {
      min-height: 260px;
        width: calc(50% - 20px);
        justify-content: flex-start;
        zoom: 1;
      }
    }
  }
}
