.contact {
    background: linear-gradient(168.55deg, #314459 28.11%, #293441 87.7%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 90px;
    padding-bottom: 80px;

    h2 {
        color: white;
    }

    &-flex {
        display: flex;
        justify-content: center;
    }

    &-form {
        margin-top: 40px;
        width: 377px;
        margin-left: 60px;
        &-input{
            margin-bottom: 42px;
            &:last-of-type{
                margin-bottom: 0px;
            }
        }
        &-submit{
            margin-top: 30px;
           
            .main-button{
                min-width: 160px;
            }
            &-message{
                font-size: 20px;
                color: white;
            }
        }
    }

    &-image {
        width: 467px;
    }
}



@media only screen and (max-width: 1000px){
    .contact{
        position: relative;
        &-form{

            margin-left: unset;
            width: 100%;
        }
        &-image{
            display: none;
        }
        &-flex{
            width: 100%;
            max-width: 500px;
            flex-direction: column;
        }
    }
}