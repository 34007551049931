.home-roadmap {
  margin-top: 200px;
  position: relative;
  &-steps {
    position: relative;
    top: -50px;
  }

  &-shape-1 {
    position: absolute;
    left: 0px;
    top: -100px;
  }
  &-shape-2 {
    position: absolute;
    right: 0px;
    top: 160px;
  }
  &-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    width: 100%;
    margin-bottom: 130px;
    &:last-of-type {
      margin-bottom: 0px;
    }
    &-figure {
    
      z-index: 9;
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      top: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
   
    }

    &-description {
      position: relative;
      max-width: 320px;
      background: white;
      padding: 30px 20px 30px 0px;
      h5 {
        font-weight: bold;
        font-size: 26px;
        margin-bottom: 15px;
        color: $main-color;
        font-family: $title-font;
      }
    }
    &:nth-child(even) {
      .home-roadmap-item-description {
        transform: translate(-200px, 80px);
      }
      .home-roadmap-item-description {
        padding-left: 30px;
      
      }
    }
    &:nth-child(odd) {
      .home-roadmap-item-description {
        transform: translate(200px, 80px);
      }
      .home-roadmap-item-description {
        padding-left: 60px;
      }
    }
   
    ul {
      li {
        color: #727183;
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 10px;
        position: relative;
        padding-left: 15px;
        line-height: 22px;
        &:last-child {
          margin-bottom: 0px;
        }
        &::before {
          width: 5px;
          height: 5px;
          background: linear-gradient(261.65deg, #14f195 3.63%, #8e49f6 100%);
          content: "";
          left: 0px;
          position: absolute;
          top: 6px;
          border-radius: 50%;
        }
      }
    }
    &:last-of-type{
      .home-roadmap-item-figure{
        top: 20px;
      }
    }
  }
  &-steps {
    position: relative;
    padding-top: 60px;
    &-bkg {
      position: absolute;
      height: calc(100% - 120px);
      left: 50%;
      top: 80px;
      transform: translate(-50%);
    }
  }

}

@media only screen and (max-width: 1000px) {
  .home-roadmap {
    margin-top: $mobile-sectiom-margin;
    &-steps {
      zoom: 0.8;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .home-roadmap {
    &-item {
      margin-bottom: 50px;
      &-description {
        left: unset !important;
        transform: unset !important;
        margin-top: 160px;
        padding: 30px !important;
        width: 100%;
        max-width: 400px;
      }
      &:last-of-type{
        .home-roadmap-item-figure{
          top: 0px;
        }
      }
    }
    &-steps {
      &-bkg {
        display: none;
      }
    }
  }
}
