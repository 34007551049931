.socials {
    &-item {
        width: 21px;
        height: 21px;
        object-fit: cover;
        margin-left: 24px;
        &:nth-child(1){
            margin-left: 0px;
        }
    }
}