@import './MergeProgress/style.scss';
@import './MergeSteps/style.scss';

.merge{
    width: 881px;
    height: 734px;
    display: flex;
}



@media only screen and (max-width: 1500px){
    .merge{
       zoom: 0.86;
    }
}