#transaction-screen-shape{
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 0;
   
}



.transaction-screen{
    background: $bg-color;

    &-lines{
        position: absolute;
        top: 0px;
        right: 30px;
        
        z-index: 0;
    
        .fading-line{
            top: 0px;
            z-index: 10;
        }
        #line-1{
            right: 10px;
        }
        #line-2{
            right: 1vw;
        }
        #line-3{
            right: 2vw;
        }
        #line-4{
            right: 4vw; 
        }
        #line-5{
            right: 25vw; 
        }
        #line-6{
            right: 38vw; 
        }
        
    }
}