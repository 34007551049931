.merge-progress {
    width: 370px;
    padding: 40px 20px 0px 60px;

    &-steps {
        margin-top: 80px;
    }

    &-step {
        transition: 0.2s all;
        font-size: 20px;
        line-height: 22px;
        color: #727183;
        margin-bottom: 50px;
        position: relative;
        padding-left: 30px;

        &-image {
            position: absolute;
            opacity: 0;
            transition: 0.2s all;
            pointer-events: none;

        }
    }

    #merge-pending-image {
        top: 50%;
        transform: translate(0, -50%);
        width: 17px;
        left: 0%;
    }

    #merge-done-image {
        top: -80%;
        transform: translate(0, -50%);
        width: 300px;
        left: -59%;
    }

    #merge-active-image {
        top: -80%;
        transform: translate(0, -50%);
        width: 300px;
        left: -59%;
    }

    #active-step {
        #merge-active-image {
            opacity: 1;
        }
    }
    #done-step{
        #merge-done-image{
            opacity: 1;
        }
    }
    #pending-step{
        #merge-pending-image{
            opacity: 1;
        }
    }

}