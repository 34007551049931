
.brands{
    &-header{
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        h3{
            font-weight: 500;
            font-size: 30px;
            line-height: 36px;
            color: $main-color;
            font-family: $title-font;
            margin-bottom: 26px
        }
        h1{
            font-weight: 900;
            font-size: 120px;
            line-height: 120px;
            font-family: $title-font;
            color: $main-color;
            margin-bottom: 10px
        }
        p{
            @extend .description-text;
            max-width: 420px;
        }
    }
    &-list{
        max-width: 915px;
        margin: 0 auto;
        margin-top: 120px;
        .brand{
    
            display: flex;
            margin-bottom: 145px;
            &:last-child{
                margin-bottom: 0px;
            }
            &-image{
                box-shadow: -20px -20px 150px #D8E6F5;
                width: 433px;
                height: 540px;
                object-fit: cover;
            }
            &-description{
                position: relative;
                flex: 1;
                h2{
                    margin-bottom: 30px
                }
                p{
                    @extend .description-text;
                    margin-bottom: 20px;
                    &:last-child{
                        margin-bottom: 0px;
                    }
                }
            }
            &-image-first{
             .brand{
                &-image{
                    order: 1;
                }
                &-description{
                    order: 2;
                    padding-left: 94px;
                }   
             }
            }
            &-description-first{
                .brand{
                    &-image{
                        order: 2;
                    }
                    &-description{
                        order: 1;
                        padding-right: 94px;
                    } 
                }
            }
           
        }
    }
}
#brands-grid{
    margin-bottom: 135px;
}


@media only screen and (max-width: 1400px) {
    .brands{
        &-header {
            h1{
                font-size: 90px;
                line-height: 90px;
            }
        }
    }
  }


@media only screen and (max-width: 1000px) {
    .brands{
        
        &-list{
            max-width: 500px;
           margin-left: auto;
           margin-right: auto;
           margin-top: 80px;
            .brand{
                flex-direction: column;
                align-items: center;
                margin-bottom: 80px;
                &-image{
                    order: 2!important;
                    width: 100%;
                    height: auto;
                    max-width: 400px;
                }
                &-description{
                    order: 1!important;
                    padding-left: 0px!important;
                    padding-right: 0px!important;
                    text-align: center;
                    margin-bottom: 40px;
                }
            }
        }
        &-header {
            h1{
                font-size: 45px;
                line-height: 45px;
            }
        }
    }
  }

  @media only screen and (max-width: 600px){
      #brands{
          h2{
              font-size: 35px;
              line-height: 35px;
          }
      }
  }