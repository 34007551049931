
.nyc-popup{
  .popup-content{
    max-width: 611px;
  }
}

.nyc {
  position: relative;
  text-align: center;
  &-registration-error{
    font-weight: 500;
    font-size: 14px;
    margin-top: 20px;
  }

  &-img{
    margin-top: 30px;
    margin-bottom: 20px;
    max-width: 365px;
    width: 90%;
  }
  &-top {
    position: relative;
    padding-bottom: 35px;
    &-line {
      position: absolute;
      bottom: 0px;
      width: 100%;
      left: 0px;
    }
  }
  &-bottom {
    padding-top: 35px;
  }
  &-content {
    padding: 40px 0px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.2s all;
      &-submitted{
        background: transparent;

        .nyc-content-grid {
          pointer-events: none;
          opacity: 0;
        }
      }
    &-grid{
      max-width: 420px;
      width: calc(100% - 30px);
    }
  }
  &-gif {
    z-index: 99;
    width: 100%;
    height: fit-content;
    position: absolute;
    padding: 20px;
    left: 0px;
    top: 0px;
    pointer-events: none;
    display: flex;
    padding-top: 50px;
    flex-direction: column;
    background: white;
    padding-bottom: 40px;
    align-items: center;
    &-text{
      color: black;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 20px;
      line-height: normal;
    }
    img{
      width: 430px;
      height: auto;
      object-fit: contain;
    }
  }
  #nyc-submit {
    min-width: 200px;
    margin-top: 20px;
    width: 100%;
  }

  p {
    line-height: 20px;
  }
  &-title {
    color: black;
    font-size: 64px;
    line-height: 90px;
    line-height: normal;
    margin-bottom: 25px;
    font-family: $title-font-bold;
    letter-spacing: 2px;
  }
  &-subtitle {
    margin-bottom: 30px;
    text-align: center;
    color: black;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    font-family: $main-font;
  }
  &-required {
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    margin-top: 30px;
    color: black;
    a {
      color: #8c34e6;
      text-decoration: underline;
    }
  }
  &-party {
    max-width: 500px;
    text-align: center;
    line-height: 22px;
    strong{
      font-weight: 700;
      font-size: 18px;
    }
    p {
      color: black;
      font-size: 14px;
      &:first-child{
        margin-bottom: 10px;
      }
    }
  }

  .input {
    border: 1px solid #314459;
    margin-top: 20px;
    color: #314459;
    font-weight: 400;
    &::placeholder {
      color: #314459;
      opacity: 0.5;
    }

    &-error {
      color: #314459;
      font-weight: 700;
    }
  }
}


@media only screen and (max-width: 600px) {
  .nyc {
    &-gif {

      img{
        width: 100%;
      }
    }
    .input{
      margin-top: 10px;
    }
    &-top{
      padding-bottom: 25px;
    }
    &-bottom{
      padding-top: 25px;
    }
    &-title{
      font-size: 40px;
      margin-bottom: 10px;
    }
    &-subtitle{
      max-width: 270px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
    }
  }
}
