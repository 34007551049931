.founders {
  @extend .smaller-grid;
  margin-top: 120px;

  &-list {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;

    .founder {
      display: flex;
      background: white;
      padding: 35px 15px 35px 35px;
      width: calc(50% - 25px);
      
      &-frame {
        width: 75px;
        height: 75px;
        position: relative;
        &-figure {
          background: #314459;
          opacity: 0.25;
        }
        &-square {
          position: absolute;
          width: 14px;
          height: 14px;
          top: 10px;
          right: -30px;
        }
        &-circle {
          position: absolute;
          width: 14px;
          height: 14px;
          top: calc(100% + 20px);
          left: 10px;
        }
      }
      &-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
      &-symbol {
        position: absolute;
        width: 90px;
        top: 25px;
        right: -48px;
        display: none;
      }

      &-details {
        flex: 1;
        padding-left: 76px;
      
        a {
          color: $link-color;
        }
        &-title {
          font-weight: 800;
          font-size: 46px;

          color: #314459;
          font-family: $title-font;
        }

        &-text {
          font-size: 14px;
          line-height: 22px;
          color: #727183;
          position: relative;
          margin-top: 30px;
        }
      }
    }
  }

  &-symbols {
    background: #ffffff;
    margin-top: 50px;
    padding-top: 30px;
    font-family: $title-font;

    &-title {
      color: #314459;
      font-weight: 800;
      text-align: center;
      font-size: 26px;
    }

    &-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 30px 40px 55px 40px;
      flex-wrap: wrap;

      img {
        width: 170px;
        height: 170px;
        object-fit: cover;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .founders {
    margin-top: 70px;

    &-list {
      gap: 20px;
      justify-content: center;

      .founder {
        width: calc(50% - 10px);
        padding-left: 15px;
        min-width: 360px;

        &-details {
          &-title {
            font-size: 30px;
            line-height: 40px;
          }
        }
      }
    }

    &-symbols {
      &-list {
        flex-wrap: wrap;
        justify-content: center;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 30px;

        img {
          width: 140px;
          height: 140px;
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .founders {
    &-list {
      .founder {
        width: 100%;
        min-width: unset;
        flex-direction: column;
        align-items: center;
        &-frame {
          zoom: 1.2;
        }
        &-details {
          padding-left: 20px;
          padding-right: 20px;
          margin-top: 70px;
          text-align: center;
          &-title {
            margin-bottom: 20px;
          }
          &-quotes {
            display: none;
          }
        }
      }
    }
  }
}
