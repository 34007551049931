.home-minting {
  padding-top: 200px;
  .flex-container-content-text{
      margin-bottom: 20px;
      color: $secondary-color;
  }
  &-flex {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: 970px;
    @extend .flex-container;
  }
  &-shape {
    position: absolute;
    left: 0px;
  }

  .flex-container-content {
    padding-left: 80px;

    .main-button {
      margin-top: auto;
    }
  }

  &-left {
    display: flex;
    flex-wrap: wrap;
    width: 440px;
    gap: 50px;
    align-items: center;

    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .home-minting {
    padding-top: 80px;
    #home-mergin-line {
      display: none;
    }
    &-shape {
      width: 300px;
    }
    &-flex {
      flex-direction: column;
      align-items: center;
    }
    &-left {
      order: 2;
    }
    .flex-container-content {
      align-items: center;
      text-align: center;
      order: 1;
      margin-bottom: 60px;
      padding-left: 0px;
      max-width: 500px;
      .main-button {
        margin-top: 50px;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .home-minting {
    overflow-x: hidden;
    &-left {
      width: fit-content;
      gap: 30px;
      justify-content: center;
      zoom: 0.9;
    }
  }
}
