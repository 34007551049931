.home-hero-shapes{
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    max-width: 1100px;
    width: calc(100% - 40px);
    top: 0px;
    height: 100%;
    *{
        position: absolute;
    }

    #shape-1{
        top: 314px;
        left: 0px;
    }
    #shape-2{
        top: 30px;
        left: 180px;
    }
    #shape-3{
       
        width: 20px;
        height: 20px;
        right: 240px;
        top: 146px;
        figure{
            opacity: 0.1;
        }
      
    }
    #shape-4{
        top: 550px;
        left: -50px;
    }
    #shape-5{
        top: 800px;
        right: -180px;
        transform: rotate(90deg);
    }
    #shape-6{
        right: -160px;
        top: 400px;
    }
    #shape-7{
        top: 780px;
        right: 150px;
    }
    #shape-8{
        top: 300px;
        right: 0px;
    }
    #shape-9{
        width: 70px;
        height: 70px;
       bottom: 170px;
        left: -40px;
       
        figure{
            opacity: 0.1;
        }
    }
    #shape-10{
        width: 60px;
        height: 60px;
        right: 60px;
        bottom: 440px;
        figure{
            opacity: 0.2;
        }
    }
    .circle{
       
        figure{
            background: white;
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }
    .square{
        figure{
            background: white;
            width: 100%;
            height: 100%;

        }
    }
}