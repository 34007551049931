@import './TransactionScreen/style.scss';
.screen {
   
}

.transaction-screen{
    padding-bottom: 100px;
    display: flex;
    align-items: center;
    flex:1;
    &-figures{
        height: 100%;
        overflow: hidden;
        position: absolute;
        right: 0px;
        width: 100%
    }
}



.screen-grid {
    @extend .grid;
}

@media only screen and (max-width: 1500px) {
    .transaction-screen{
        padding-top: 140px;
    }
  }

  @media only screen and (max-width: 1150px) {
    .transaction-screen {
        padding-top: 100px;
    }
  }