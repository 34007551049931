.counter {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;


  &-title {
    font-size: 14px;
    margin-bottom: 10px;
    color: #727183;
  }
  .input-container {
    width: 66px;
    height: 42px;
    position: relative;
    border: 1px solid #314459;
    &-btns{
      position: absolute;
      width: 16px;
      top: 50%;
      transform: translate(0, -50%);
      right: 5px;
      &-top{
        transform: rotate(180deg);
      }
      &-bottom{

      }
    }
   
    input {
      font-weight: bold;
      width: calc(100% - 20px);
      height: 100%;
      border: 0px;
      background: transparent;
      font-size: 16px;
      text-align: center;
      color: #314459;
      &::placeholder {
        opacity: 0.6;
      }
    }
  }
}
