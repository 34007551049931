

.home-mergin {
    position: relative;
    margin-top: 270px;
    #mergin-shape{
        position: absolute;
        right: 0px;
        top: -200px;
        z-index: 0;
    }
    &-flex{
       margin: 0 auto;
       max-width: 970px;
        @extend .flex-container;
       
        display: flex;
        height: fit-content;
        position: relative;
        align-items: stretch;
    }
   

    &-left {
        max-width: 520px;
        position: relative;
       .flex-container-content-text{
           max-width: 460px;
           color: $secondary-color;
       }
       h2{
        width: 100%;
       }
        .main-button {
            margin-top: 60px;
            width: fit-content;
        }
    }

    &-right {
        right: -50px;
        top: -50px;
        position: absolute;
        height: 460px;
    }
    #home-mergin-line{
        top: -200px;
        right: -27px;
        height: 750px;
    }
}

@media only screen and (max-width: 1000px){
    .home-mergin{
        margin-top: $mobile-sectiom-margin;
        overflow-x: hidden;
        #home-mergin-line{
            display: none;
        }
        #mergin-shape{
            width: 300px;
            top: 300px;
        }
        &-right{
            position: relative;
            right: unset;
            top: unset;
            width: 500px;
            object-fit: contain;           
        }
        &-left{
            flex-direction: column;
            align-items: center;
            max-width: 500px;
        }
       &-flex{
        flex-direction: column;
        text-align: center;
        align-items: center;
       }
    }
}